<template>
    <div>
        <v-card>
            <v-container fluid id="v-container-alert" style="padding: 5px 15px">
                <v-row>
                    <v-col cols="8" class="pt-0 pb-0">
                        <v-menu offset-y dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    x-small
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    id="alertPeriod"
                                >
                                    Auto refresh: {{ selectedPeriod }} s
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="item in periodOptions"
                                    :key="item"
                                    v-on:click="changeRefreshPeriod(item)"
                                >
                                    <v-list-item-title
                                        >{{ item }} s</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu offset-y dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    x-small
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-1 mr-1"
                                    id="alertColumnNumber"
                                >
                                    Col Size: {{ selectedColumnSize }}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="item in columnList"
                                    :key="item"
                                    v-on:click="changeColumn(item)"
                                >
                                    <v-list-item-title
                                        >{{ item }} Cols</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn
                            x-small
                            color="primary"
                            v-on:click="sort()"
                            id="alertChangeApply"
                            >Apply</v-btn
                        >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="pt-0 pb-0">
                        <template>
                            <div class="text-center">
                                <v-btn
                                    icon
                                    x-small
                                    :disabled="selectedProfile.id === 0"
                                    @click="saveLayout"
                                    :loading="loading"
                                    color="blue-grey"
                                    class="ma-2 white--text"
                                    style="
                                        display: flex;
                                        float: right;
                                        margin: 0px !important;
                                    "
                                    id="alertSaveChange"
                                >
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon v-bind="attrs" v-on="on">
                                                mdi-content-save-all
                                            </v-icon>
                                        </template>
                                        <span
                                            >Click to save current alert page
                                            layout</span
                                        >
                                    </v-tooltip>
                                </v-btn>

                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            x-small
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="loading"
                                            color="blue-grey"
                                            id="alertAddNewChart"
                                            class="ma-2 white--text"
                                            style="
                                                display: flex;
                                                float: right;
                                                margin: 0px 5px 0px 5px !important;
                                            "
                                        >
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        mdi-plus-thick
                                                    </v-icon>
                                                </template>
                                                <span
                                                    >Click to select what to
                                                    display in alert page</span
                                                >
                                            </v-tooltip>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(
                                                item, index
                                            ) in unselectedChart"
                                            :key="index"
                                            @click="addChart(item)"
                                        >
                                            <v-list-item-title>{{
                                                allAlertOptions[item]
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-btn
                                    icon
                                    x-small
                                    :loading="loading"
                                    @click="getDisplayReady"
                                    color="blue-grey"
                                    class="ma-2 white--text"
                                    style="
                                        display: flex;
                                        float: right;
                                        margin: 0px !important;
                                    "
                                    id="alertRevert"
                                >
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon v-bind="attrs" v-on="on">
                                                mdi-undo
                                            </v-icon>
                                        </template>
                                        <span
                                            >Click to revert the layout to the
                                            last saved version</span
                                        >
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <grid-layout
            :layout.sync="layout_grid"
            :col-num="standardColNum"
            :rowHeight="1"
            :is-draggable="true"
            :is-resizable="true"
            :use-css-transforms="true"
            :margin="[0, 0]"
            :vertical-compact="true"
        >
            <grid-item
                id="fastTrade"
                v-show="alertLists.find((i) => i === 'fastTrade')"
                class="alertGridElement"
                i="fastTrade"
                key="fastTrade"
                :w="gridFastTrade.w || 0"
                :h="gridFastTrade.h || 0"
                :x="gridFastTrade.x || 0"
                :y="gridFastTrade.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <FastTrade
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('fastTrade')"
                    :height="gridFastTrade.h"
                    :showing="alertLists.includes('fastTrade')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="largeVolume"
                v-show="alertLists.find((i) => i === 'largeVolume')"
                i="largeVolume"
                key="largeVolume"
                class="alertGridElement"
                :w="gridLargeVolume.w || 0"
                :h="gridLargeVolume.h || 0"
                :x="gridLargeVolume.x || 0"
                :y="gridLargeVolume.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <LargeVolume
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('largeVolume')"
                    :height="gridLargeVolume.h"
                    :showing="alertLists.includes('largeVolume')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="largeVolumeUSD"
                v-show="alertLists.find((i) => i === 'largeVolumeUSD')"
                i="largeVolumeUSD"
                key="largeVolumeUSD"
                class="alertGridElement"
                :w="gridLargeVolumeUSD.w || 0"
                :h="gridLargeVolumeUSD.h || 0"
                :x="gridLargeVolumeUSD.x || 0"
                :y="gridLargeVolumeUSD.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <LargeVolumeUSD
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('largeVolumeUSD')"
                    :height="gridLargeVolumeUSD.h"
                    :showing="alertLists.includes('largeVolumeUSD')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="largeExposure"
                v-show="alertLists.find((i) => i === 'largeExposure')"
                i="largeExposure"
                key="largeExposure"
                class="alertGridElement"
                :w="gridLargeExposure.w || 0"
                :h="gridLargeExposure.h || 0"
                :x="gridLargeExposure.x || 0"
                :y="gridLargeExposure.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <LargeExposure
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('largeExposure')"
                    :height="gridLargeExposure.h"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="lockingPosition"
                v-show="alertLists.find((i) => i === 'lockingPosition')"
                i="lockingPosition"
                key="lockingPosition"
                class="alertGridElement"
                :w="gridLockingPosition.w || 0"
                :h="gridLockingPosition.h || 0"
                :x="gridLockingPosition.x || 0"
                :y="gridLockingPosition.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <LockingPosition
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('lockingPosition')"
                    :height="gridLockingPosition.h"
                    :showing="alertLists.includes('lockingPosition')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="mandate"
                v-show="alertLists.find((i) => i === 'mandate')"
                i="mandate"
                key="mandate"
                class="alertGridElement"
                :w="gridMandate.w || 0"
                :h="gridMandate.h || 0"
                :x="gridMandate.x || 0"
                :y="gridMandate.y || 0"
                style="padding: 5px"
                :minW="8"
                :minH="300"
                @moved="movedEvent"
            >
                <Mandate
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('mandate')"
                    :height="gridMandate.h"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="profitTaker"
                v-show="alertLists.find((i) => i === 'profitTaker')"
                i="profitTaker"
                key="profitTaker"
                class="alertGridElement"
                :w="gridProfitTaker.w || 0"
                :h="gridProfitTaker.h || 0"
                :x="gridProfitTaker.x || 0"
                :y="gridProfitTaker.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <ProfitTaker
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('profitTaker')"
                    :height="gridProfitTaker.h"
                    :showing="alertLists.includes('profitTaker')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="tradeOnCredit"
                v-show="alertLists.find((i) => i === 'tradeOnCredit')"
                i="tradeOnCredit"
                key="tradeOnCredit"
                class="alertGridElement"
                :w="gridTradeOnCredit.w || 0"
                :h="gridTradeOnCredit.h || 0"
                :x="gridTradeOnCredit.x || 0"
                :y="gridTradeOnCredit.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <TradeOnCredit
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('tradeOnCredit')"
                    :height="gridTradeOnCredit.h"
                    :showing="alertLists.includes('tradeOnCredit')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="watchList"
                v-show="alertLists.find((i) => i === 'watchList')"
                i="watchList"
                key="watchList"
                class="alertGridElement"
                :w="gridWatchList.w || 0"
                :h="gridWatchList.h || 0"
                :x="gridWatchList.x || 0"
                :y="gridWatchList.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <WatchList
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('watchList')"
                    :height="gridWatchList.h"
                    :showing="alertLists.includes('watchList')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="dailyWinner"
                v-show="alertLists.find((i) => i === 'dailyWinner')"
                i="dailyWinner"
                key="dailyWinner"
                class="alertGridElement"
                :w="gridDailyWinner.w || 0"
                :h="gridDailyWinner.h || 0"
                :x="gridDailyWinner.x || 0"
                :y="gridDailyWinner.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <DailyWinner
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('dailyWinner')"
                    :height="gridDailyWinner.h"
                    :showing="alertLists.includes('dailyWinner')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="dailyLoser"
                v-show="alertLists.find((i) => i === 'dailyLoser')"
                i="dailyLoser"
                key="dailyLoser"
                class="alertGridElement"
                :w="gridDailyLoser.w || 0"
                :h="gridDailyLoser.h || 0"
                :x="gridDailyLoser.x || 0"
                :y="gridDailyLoser.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <DailyLoser
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('dailyLoser')"
                    :height="gridDailyLoser.h"
                    :showing="alertLists.includes('dailyLoser')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="largeExposureVolume"
                v-show="alertLists.find((i) => i === 'largeExposureVolume')"
                i="largeExposureVolume"
                key="largeExposureVolume"
                class="alertGridElement"
                :w="gridLargeExposureVolume.w || 0"
                :h="gridLargeExposureVolume.h || 0"
                :x="gridLargeExposureVolume.x || 0"
                :y="gridLargeExposureVolume.y || 0"
                style="padding: 5px"
                :minW="7"
                :minH="300"
                @moved="movedEvent"
            >
                <LargeExposureVolume
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('largeExposureVolume')"
                    :height="gridLargeExposureVolume.h"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="depositWithdrawal"
                v-show="alertLists.find((i) => i === 'depositWithdrawal')"
                i="depositWithdrawal"
                key="depositWithdrawal"
                class="alertGridElement"
                :w="gridDepositWithdrawal.w || 0"
                :h="gridDepositWithdrawal.h || 0"
                :x="gridDepositWithdrawal.x || 0"
                :y="gridDepositWithdrawal.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <DepositWithdrawal
                    :showing="alertLists.includes('depositWithdrawal')"
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('depositWithdrawal')"
                    :height="gridDepositWithdrawal.h"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="sameDirection"
                v-show="alertLists.find((i) => i === 'sameDirection')"
                i="sameDirection"
                key="sameDirection"
                class="alertGridElement"
                :w="gridSameDirection.w || 0"
                :h="gridSameDirection.h || 0"
                :x="gridSameDirection.x || 0"
                :y="gridSameDirection.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <SameDirection
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('sameDirection')"
                    :height="gridSameDirection.h"
                    :showing="alertLists.includes('sameDirection')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
            <grid-item
                id="tick"
                v-show="alertLists.find((i) => i === 'tick')"
                i="tick"
                key="tick"
                class="alertGridElement"
                :w="gridTick.w || 0"
                :h="gridTick.h || 0"
                :x="gridTick.x || 0"
                :y="gridTick.y || 0"
                style="padding: 5px"
                :minW="6"
                :minH="300"
                @moved="movedEvent"
            >
                <Tick
                    :brokerId="selectedBrokerId"
                    :broker="selectedBroker"
                    @onRemove="removeChart('tick')"
                    :height="gridTick.h"
                    :showing="alertLists.includes('tick')"
                    :showConfig="showConfigIcon"
                />
            </grid-item>
        </grid-layout>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5"> Unsaved changes </v-card-title>

                <v-card-text>
                    You have unsaved window position changes. Would you like to
                    save and apply the changes?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        size="small"
                        color="green"
                        text
                        @click="saveLayout()"
                    >
                        Save & Apply
                    </v-btn>
                    <v-btn
                        color="red"
                        size="small"
                        text
                        @click="proceedFurther"
                    >
                        Discard & continue
                    </v-btn>
                    <v-btn
                        color="grey"
                        size="small"
                        text
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <AlertConfigDialog />
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import VueGridLayout from "vue-grid-layout";
import DepositWithdrawal from "./component/ComponentDepositWithdrawal";
import FastTrade from "./component/ComponentFastTrade";
import LargeVolume from "./component/ComponentLargeVolume";
import LargeVolumeUSD from "./component/ComponentLargeVolumeUSD";
import ProfitTaker from "./component/ComponentProfitTaker";
import SameDirection from "./component/ComponentSameDirection";
import DailyLoser from "./component/ComponentDailyLoser";
import DailyWinner from "./component/ComponentDailyWinner";
import LargeExposure from "./component/ComponentLargeExposure";
import LargeExposureVolume from "./component/ComponentLargeExposureVolume";
import LockingPosition from "./component/ComponentLockingPosition";
import Mandate from "./component/ComponentMandate";
import WeightedVolume from "./component/ComponentWeightedVolume";
import Tick from "./component/ComponentTick";
import TradeOnCredit from "./component/ComponentTradeOnCredit";
import WatchList from "./component/ComponentWatchList";
import AlertConfigDialog from "./ComponentAlertConfigDialog";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { position } from "@components/mixins/position";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { putAuthUser } from "@services/account/auth";

export default {
    components: {
        DepositWithdrawal,
        LargeVolume,
        LargeVolumeUSD,
        ProfitTaker,
        FastTrade,
        LargeExposure,
        WeightedVolume,
        LockingPosition,
        Mandate,
        TradeOnCredit,
        WatchList,
        DailyWinner,
        DailyLoser,
        LargeExposureVolume,
        SameDirection,
        Tick,
        AlertConfigDialog,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
    },
    mixins: [helper, position, permission],
    data() {
        return {
            tutorialConstant: TutorialConstant,
            moved: false,
            layout_grid: [],
            standardHeight: 1,
            standardColNum: 36,
            showConfigIcon: false,
            selectedColumnSize: 4,
            columnList: [2, 3, 4, 6],
            visible: false,
            alertLists: [],
            defaultLists: [
                "fastTrade",
                "largeExposure",
                "largeVolume",
                "largeVolumeUSD",
                "lockingPosition",
                "mandate",
                "profitTaker",
                "tradeOnCredit",
                "watchList",
                "dailyWinner",
                "dailyLoser",
                "largeExposureVolume",
                "depositWithdrawal",
                "sameDirection",
                "tick",
            ],
            allAlertOptions: {
                fastTrade: "Fast Trade",
                largeExposure: "Large Exposure",
                largeVolume: "Big Lot",
                largeVolumeUSD: "Large Volume USD",
                weightedVolume: "Weighted Volume",
                lockingPosition: "Locking Position",
                mandate: "Mandate",
                profitTaker: "Profit Taker",
                tradeOnCredit: "Trade On Credit",
                watchList: "Watch List",
                dailyWinner: "Daily Winner",
                dailyLoser: "Daily Loser",
                largeExposureVolume: "Large Exposure Volume",
                depositWithdrawal: "Deposit Withdrawal",
                sameDirection: "Same Direction",
                tick: "Tick",
            },
            unselectedChart: [],
            monitorSocket: null,
            selectedBroker: "",
            selectedBrokerId: -1,
            monitorList: [
                "LARGE_EXPOSURE_VOLUME_MONITOR",
                "LARGE_EXPOSURE_MONITOR",
                "MANDATE_MONITOR",
                "LOCKING_POSITION_MONITOR",
                "DAILY_WINNER_LOSER_MONITOR",
            ],
            dialog: false,
            moveToNext: null,
            toPath: "",
            overlay: false,
        };
    },
    computed: {
        ...mapState("Alert", [
            "settings",
            "selectedProfile",
            "allProfile",
            "loading",
            "clickedOutside",
            "clickedOutside",
            "selectedPeriod",
            "periodOptions",
            "alertTutorial",
        ]),
        gridLargeVolume() {
            return (
                this.layout_grid.find((item) => item?.i === "largeVolume") || {}
            );
        },
        gridLargeVolumeUSD() {
            return (
                this.layout_grid.find((item) => item?.i === "largeVolumeUSD") ||
                {}
            );
        },
        gridFastTrade() {
            return (
                this.layout_grid.find((item) => item?.i === "fastTrade") || {}
            );
        },
        gridLargeExposure() {
            return (
                this.layout_grid.find((item) => item?.i === "largeExposure") ||
                {}
            );
        },
        gridLockingPosition() {
            return (
                this.layout_grid.find(
                    (item) => item?.i === "lockingPosition"
                ) || {}
            );
        },
        gridMandate() {
            return this.layout_grid.find((item) => item?.i === "mandate") || {};
        },
        gridWeightedVolume() {
            return (
                this.layout_grid.find((item) => item?.i === "weightedVolume") ||
                {}
            );
        },
        gridProfitTaker() {
            return (
                this.layout_grid.find((item) => item?.i === "profitTaker") || {}
            );
        },
        gridTradeOnCredit() {
            return (
                this.layout_grid.find((item) => item?.i === "tradeOnCredit") ||
                {}
            );
        },
        gridWatchList() {
            return (
                this.layout_grid.find((item) => item?.i === "watchList") || {}
            );
        },
        gridDailyWinner() {
            return (
                this.layout_grid.find((item) => item?.i === "dailyWinner") || {}
            );
        },
        gridDailyLoser() {
            return (
                this.layout_grid.find((item) => item?.i === "dailyLoser") || {}
            );
        },
        gridLargeExposureVolume() {
            return (
                this.layout_grid.find(
                    (item) => item?.i === "largeExposureVolume"
                ) || {}
            );
        },
        gridDepositWithdrawal() {
            return (
                this.layout_grid.find(
                    (item) => item?.i === "depositWithdrawal"
                ) || {}
            );
        },
        gridSameDirection() {
            return (
                this.layout_grid.find((item) => item?.i === "sameDirection") ||
                {}
            );
        },
        gridTick() {
            return this.layout_grid.find((item) => item?.i === "tick") || {};
        },
    },
    watch: {
        alertTutorial(nv) {
            if (!!nv) {
                this.getTutorial()
                    .oncomplete(() => this.updateAlertTutorialAction(false))
                    .onexit(() => this.updateAlertTutorialAction(false))
                    .start();
            }
        },
        selectedProfile(nv) {
            this.overlay = false;
            if (nv.unselected.length) {
                this.unselectedChart = nv.unselected
                    .filter((item) => this.defaultLists.includes(item))
                    .sort();
            } else {
                this.unselectedChart = [];
            }
            if (nv.id === 0) {
                this.alertLists = [...this.defaultLists];
            }
            this.getDisplayReady();
            setTimeout(() => {
                if (
                    !localStorage
                        .getItem("tutorial_presented")
                        .includes("alert_page")
                ) {
                    this.getTutorial()
                        .oncomplete(function () {
                            const params = { pages: "" };
                            if (!localStorage.getItem("tutorial_presented")) {
                                params.pages = "alert_page";
                            } else {
                                params.pages =
                                    localStorage.getItem("tutorial_presented") +
                                    ",alert_page";
                            }
                            putAuthUser(params).then((res) => {
                                if (res.status === 200) {
                                    localStorage.setItem(
                                        "tutorial_presented",
                                        params.pages
                                    );
                                }
                            });
                        })
                        .onexit(function () {
                            const params = { pages: "" };
                            if (!localStorage.getItem("tutorial_presented")) {
                                params.pages = "alert_page";
                            } else {
                                params.pages =
                                    localStorage.getItem("tutorial_presented") +
                                    ",alert_page";
                            }
                            putAuthUser(params).then((res) => {
                                if (res.status === 200) {
                                    localStorage.setItem(
                                        "tutorial_presented",
                                        params.pages
                                    );
                                }
                            });
                        })
                        .start();
                }
            }, 500);
        },
    },
    methods: {
        ...mapActions("Alert", [
            "putProfileAction",
            "putUserProfileAction",
            "updateAlertTutorialAction",
        ]),
        ...mapActions("Home", ["getSymbolDPAction"]),
        ...mapActions("FastTrade", ["processFastTrade"]),
        ...mapActions("LargeVolume", ["processLargeVolume"]),
        ...mapActions("LargeVolumeUSD", ["processLargeVolumeUSD"]),
        ...mapActions("WeightedVolume", ["processWeightedVolume"]),
        ...mapActions("LockingPosition", ["processLockingPosition"]),
        ...mapActions("ProfitTaker", ["processProfitTaker"]),
        ...mapActions("TOC", ["processTradeOnCredit"]),
        ...mapActions("WatchList", ["processWatchList"]),
        ...mapActions("DailyWinner", ["processDailyWinner"]),
        ...mapActions("DailyLoser", ["processDailyLoser"]),
        ...mapActions("DepositWithdrawal", ["processDepositWithdrawal"]),
        ...mapActions("SameDirection", ["processSameDirection"]),
        ...mapActions("Tick", ["processTick"]),
        ...mapActions("LargeExposure", ["processLargeExposure"]),
        ...mapActions("Mandate", ["processChartMandate"]),
        ...mapActions("LargeExposureVolume", ["processLargeExposureVolume"]),
        ...mapMutations("Alert", ["UPDATE_REFRESH_PERIOD"]),
        getTutorial() {
            const steps = [];
            if (
                !!Array.from(
                    document.getElementsByClassName("alertGridElement")
                ).find((item) => item.style.display !== "none")
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertGridElement")
                    ).find((item) => item.style.display !== "none"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertGridElement"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertGridElement"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertRefresh")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertRefresh")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertRefresh"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertRefresh"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertCalender")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertCalender")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertCalender"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertCalender"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertDownload")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertDownload")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertDownload"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertDownload"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertConfig")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertConfig")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertConfig"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertConfig"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertClose")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertClose")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertClose"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertClose"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("alertBookSelection")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("alertBookSelection")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertBookSelection"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertBookSelection"
                    ]["intro"],
                });
            }
            if (
                !!Array.from(
                    document.getElementsByClassName("vue-resizable-handle")
                ).find((item) =>
                    item.checkVisibility({ visibilityProperty: true })
                )
            ) {
                steps.push({
                    element: Array.from(
                        document.getElementsByClassName("vue-resizable-handle")
                    ).find((item) =>
                        item.checkVisibility({ visibilityProperty: true })
                    ),
                    title: this.tutorialConstant["alertPageIntro"][
                        "vue-resizable-handle"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "vue-resizable-handle"
                    ]["intro"],
                });
            }
            steps.push(
                {
                    element: document.getElementById("alertPeriod"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertPeriod"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertPeriod"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertColumnNumber"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertColumnNumber"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertColumnNumber"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertChangeApply"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertChangeApply"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertChangeApply"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertRevert"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertRevert"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertRevert"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertAddNewChart"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertAddNewChart"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertAddNewChart"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertSaveChange"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertSaveChange"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertSaveChange"
                    ]["intro"],
                },
                {
                    element: document.getElementById("alertPageProfile"),
                    title: this.tutorialConstant["alertPageIntro"][
                        "alertPageProfile"
                    ]["title"],
                    intro: this.tutorialConstant["alertPageIntro"][
                        "alertPageProfile"
                    ]["intro"],
                }
            );
            return introJs().setOptions({
                steps: steps,
            });
        },
        /**
         * Change column size
         * @param   {[type]}  col  [col description]
         * @return  {[type]}       [return description]
         */
        changeColumn(col) {
            this.selectedColumnSize = col;
        },
        /**
         * Sort all tables
         * @return  {[type]}  [return description]
         */
        sort() {
            this.moved = true;
            const maxHeight = window.innerHeight - 40;

            const baseHeight = maxHeight / 2.6;
            this.layout_grid = [];
            for (const item of this.alertLists) {
                this.layout_grid.push({
                    x:
                        (this.layout_grid.length *
                            (this.standardColNum / this.selectedColumnSize)) %
                        this.standardColNum,
                    y: this.layout_grid.length + 1,
                    w: this.standardColNum / this.selectedColumnSize,
                    h: baseHeight,
                    i: item,
                });
            }
        },
        getDisplayReady() {
            this.moved = false;
            const positionSettings = this.selectedProfile.positions;
            this.alertLists = [];
            let copiedList = [...this.defaultLists];
            this.unselectedChart.map((item) => {
                copiedList = copiedList.filter((e) => e !== item);
            });
            this.alertLists = copiedList;
            this.layout_grid = [];

            this.alertLists.map((item) => {
                const itemPos = positionSettings.find((i) => i.alert == item);
                if (itemPos === undefined) {
                    this.layout_grid.push({
                        x: 0,
                        y: 100000,
                        w: 9,
                        h: 300,
                        i: item,
                    });
                } else {
                    this.layout_grid.push({
                        x:
                            itemPos.posX > this.standardColNum
                                ? (this.layout_grid.length *
                                      (this.standardColNum /
                                          this.selectedColumnSize)) %
                                  this.standardColNum
                                : itemPos.posX,
                        y: itemPos.posY,
                        w:
                            itemPos.width > this.standardColNum
                                ? this.standardColNum / this.selectedColumnSize
                                : itemPos.width,
                        h: itemPos.height,
                        i: item,
                    });
                }
            });
        },
        removeChart(chartName) {
            this.moved = true;
            this.unselectedChart = [...this.unselectedChart, chartName].sort();
            this.alertLists = this.alertLists.filter(
                (item) => item !== chartName
            );
            this.layout_grid = this.layout_grid.filter(
                (item) => item.i !== chartName
            );
        },
        addChart(chartName) {
            this.moved = true;
            const maxHeight = window.innerHeight - 40;

            const baseHeight = maxHeight / 2.6;

            this.unselectedChart = this.unselectedChart.filter(
                (item) => item !== chartName
            );
            this.alertLists = [...this.alertLists, chartName];
            this.layout_grid = [
                ...this.layout_grid,
                {
                    x: 0,
                    y: this.layout_grid.length * baseHeight + 1,
                    w: this.standardColNum / this.selectedColumnSize,
                    h: baseHeight,
                    i: chartName,
                },
            ];
        },
        saveLayout() {
            const outputPos = this.layout_grid.map((item) => {
                return {
                    alert: item.i,
                    width: item.w,
                    height: item.h,
                    posX: item.x,
                    posY: item.y,
                };
            });

            const params = {
                id: this.selectedProfile.id,
                profile_name: this.selectedProfile.text,
                value: {
                    settings: this.selectedProfile.settings,
                    positions: outputPos,
                    unselected: this.unselectedChart,
                    unselectedBooks: this.selectedProfile.unselectedBooks,
                },
            };

            this.putProfileAction(params).then(() => {
                setTimeout(() => {
                    this.proceedFurther();
                }, 1000);
            });
        },
        movedEvent() {
            this.moved = true;
        },
        proceedFurther() {
            this.dialog = false;
            if (this.moveToNext !== null) {
                this.moveToNext();
            }
        },
        changeRefreshPeriod(period) {
            this.UPDATE_REFRESH_PERIOD(period);
        },
    },
    created() {
        this.moved = false;
        this.getSymbolDPAction();
        this.showConfigIcon =
            false ||
            (JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.account?.child?.includes("config") &&
                JSON.parse(
                    localStorage.getItem("permission")
                )?.frontPermission?.settings?.child?.includes("alertConfig"));

        const eventPumpingUrl =
            (document.querySelector("body").getAttribute("apiurl") ||
                process.env.VUE_APP_ENDPOINT) + "/api/v1/pumping";

        var source = new EventSource(eventPumpingUrl);

        source.onmessage = (e) => {
            const IncomingData = JSON.parse(e.data);
            switch (IncomingData.alert_name) {
                case "Fast Trade":
                    this.processFastTrade(IncomingData);
                    break;
                case "Big Lot":
                    this.processLargeVolume(IncomingData);
                    break;
                case "Large Volume USD":
                    this.processLargeVolumeUSD(IncomingData);
                    break;
                // case "Locking Position":
                //     this.processLockingPosition(IncomingData);
                //     break;
                case "Profit Taker":
                    this.processProfitTaker(IncomingData);
                    break;
                case "Trade On Credit":
                    this.processTradeOnCredit(IncomingData);
                    break;
                case "Watch List":
                    this.processWatchList(IncomingData);
                    break;
                // case "Daily Winner":
                //     this.processDailyWinner(IncomingData);
                //     break;
                // case "Daily Loser":
                //     this.processDailyLoser(IncomingData);
                //     break;
                case "Deposit Withdrawal":
                    this.processDepositWithdrawal(IncomingData);
                    break;
                case "Same Direction":
                    this.processSameDirection(IncomingData);
                    break;
                case "Tick":
                    this.processTick(IncomingData);
                    break;
                default:
                    console.log(IncomingData);
                    break;
            }
        };
    },
    // mounted() {
    //     this.overlay = true;
    //     const websocketUrl = document
    //         .querySelector("body")
    //         .getAttribute("socketurl")
    //         ? document.querySelector("body").getAttribute("socketurl") +
    //           "/publisher-monitor-svc"
    //         : process.env.VUE_APP_SOCKET_ALERT;

    //     // establish socket connection
    //     this.monitorSocket = new WebSocket(websocketUrl);

    //     // send token to backend websocket on initial status
    //     this.monitorSocket.onopen = function () {
    //         const data = {
    //             token: localStorage.getItem("token"),
    //             externalMsgType: "CMD_AUTHORIZATION",
    //         };
    //         self.monitorSocket.send(JSON.stringify(data));
    //     };

    //     const self = this;

    //     // on socket message coming
    //     this.monitorSocket.onmessage = function (evt) {
    //         let msg = evt.data;

    //         msg = msg.replace("$_", "");

    //         // decode data
    //         let decodedMsg = self.unzipVersion(msg);
    //         // console.log(decodedMsg);
    //         // if data is in string format, parse it
    //         if (typeof decodedMsg === "string") {
    //             decodedMsg = JSON.parse(decodedMsg);
    //         }

    //         if (
    //             decodedMsg.externalMsgType === "RESPONSE_AUTH" &&
    //             decodedMsg.code === "OK"
    //         ) {
    //             const monitorMain = {
    //                 externalMsgType: "CMD_SUBSCRIBE_MONITORS",
    //                 monitors: self.monitorList,
    //             };
    //             self.monitorSocket.send(JSON.stringify(monitorMain));
    //         }

    //         if (decodedMsg.externalMsgType === "DATA_MONITOR_RESULT") {
    //             // console.log(decodedMsg)
    //             // if (
    //             //     decodedMsg.monitorType ===
    //             //         "LARGE_EXPOSURE_VOLUME_MONITOR" &&
    //             //     self.alertLists.includes("largeExposureVolume")
    //             // ) {
    //             //     self.processLargeExposureVolume(decodedMsg.panelMap);
    //             // }

    //             // if (
    //             //     decodedMsg.monitorType === "LARGE_EXPOSURE_MONITOR" &&
    //             //     self.alertLists.includes("largeExposure")
    //             // ) {
    //             //     self.processLargeExposure(decodedMsg.panelMap);
    //             // }

    //             // if (
    //             //     decodedMsg.monitorType === "MANDATE_MONITOR" &&
    //             //     self.alertLists.includes("mandate")
    //             // ) {
    //             //     self.processChartMandate(decodedMsg.panelMap);
    //             // }

    //             // if (
    //             //     decodedMsg.monitorType === "LOCKING_POSITION_MONITOR" &&
    //             //     self.alertLists.includes("lockingPosition")
    //             // ) {
    //             //     self.processLockingPosition(decodedMsg.panelMap);
    //             // }

    //             // if (
    //             //     decodedMsg.monitorType === "DAILY_WINNER_LOSER_MONITOR" &&
    //             //     self.alertLists.includes("dailyWinner")
    //             // ) {
    //             //     self.processDailyWinner(decodedMsg.panelMap);
    //             // }

    //             // if (
    //             //     decodedMsg.monitorType === "DAILY_WINNER_LOSER_MONITOR" &&
    //             //     self.alertLists.includes("dailyLoser")
    //             // ) {
    //             //     self.processDailyLoser(decodedMsg.panelMap);
    //             // }
    //         }
    //     };

    //     this.monitorSocket.onclose = (evt) => {
    //         const websocketUrl =
    //             process.env.VUE_APP_SOCKET ||
    //             document.querySelector("body").getAttribute("socketurl") +
    //                 "/publisher-monitor-svc";

    //         // establish socket connection
    //         this.monitorSocket = new WebSocket(websocketUrl);
    //         console.log("on close: ", evt);
    //     };

    //     this.monitorSocket.onerror = (evt) => {
    //         const websocketUrl =
    //             process.env.VUE_APP_SOCKET ||
    //             document.querySelector("body").getAttribute("socketurl") +
    //                 "/publisher-monitor-svc";

    //         // establish socket connection
    //         this.monitorSocket = new WebSocket(websocketUrl);
    //         console.log("On error: ", evt);
    //     };
    // },
    beforeRouteLeave(to, _1, next) {
        this.toPath = to;
        if (
            this.selectedProfile.id === 0 ||
            this.selectedProfile.positions === undefined ||
            this.moved == false
        ) {
            next();
        } else {
            this.dialog = true;
            this.moveToNext = () => {
                next();
            };
        }
    },
    beforeDestroy() {
        if (this.toPath !== "/signin") {
            const params = {
                meta_value: "alert",
                profile_id: localStorage.getItem("alertId"),
            };

            this.putUserProfileAction(params);
        }
    },
    destroyed() {
        this.monitorSocket.close();
    },
};
</script>

<style scoped>
.vue-grid-item {
    cursor: default !important;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    touch-action: none;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item > .vue-resizable-handle {
    color: coral;
}
.vdr >>> tr {
    height: 29px;
}
div >>> .handle {
    background: #525252;
    border: 1px solid #525252;
}
div >>> .handle-tm {
    width: 100%;
    height: 5px;
    left: 5px;
    top: -5px;
}
div >>> .handle-bm {
    width: 100%;
    height: 5px;
    left: 5px;
    bottom: -5px;
}
div >>> .handle-mr {
    height: 100%;
    top: 5px;
    width: 5px;
    right: -5px;
}
div >>> .handle-ml {
    height: 100%;
    top: 5px;
    width: 5px;
    left: -5px;
}
div >>> .handle-tl {
    border-radius: 50%;
    top: -5px;
    left: -5px;
}
div >>> .handle-tr {
    border-radius: 50%;
    top: -5px;
    right: -5px;
}
div >>> .handle-bl {
    border-radius: 50%;
    bottom: -5px;
    left: -5px;
}
div >>> .handle-br {
    border-radius: 50%;
    bottom: -5px;
    right: -5px;
}
</style>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr {
    white-space: nowrap;
}
</style>
